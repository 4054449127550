import React from "react";
import { Link } from "react-router-dom";

export default function OCVButtonBar(props: any) {
  const { viewData, anchorID } = props;
  return (
    <div
      id={anchorID}
      className="w-auto h-auto bg-cover"
      style={{
        backgroundColor: viewData.config.bgColor,
        backgroundImage:
          viewData.config.rgb !== undefined &&
          viewData.config.backgroundImageOpacity !== undefined
            ? `linear-gradient(rgba(${viewData.config.rgb}, ${viewData.config.rgb}, ${viewData.config.rgb}, ${viewData.config.backgroundImageOpacity}), 
        rgba(${viewData.config.rgb}, ${viewData.config.rgb}, ${viewData.config.rgb}, ${viewData.config.backgroundImageOpacity})), url(${viewData.config.backgroundImageURL})`
            : `url(${viewData.config.backgroundImageURL})`,
      }}
    >
      <ul
        className={`flex justify-center space-x-10 md:space-x-16 h-20 w-auto px-2`}
      >
        <li
          className="self-center text-md sm:text-2xl text-center"
          style={{ color: viewData.config.textColor }}
        >
          {viewData.title}
        </li>
        <li className="self-center text-center text-xl">
          {viewData.config.feature ? (
            <Link to={viewData.config.feature}>
              <button
                type="button"
                className="border px-3 md:px-16 py-1 border-white"
                style={{ color: viewData.config.buttonTextColor }}
              >
                {viewData.config.buttonText}
              </button>
            </Link>
          ) : (
            <a href={viewData.url} rel="noopener noreferrer" target="_blank">
              <button
                type="button"
                className="text-white border text-center px-3 md:px-16 py-1 border-white"
                style={{ color: viewData.config.buttonTextColor }}
              >
                {viewData.config.buttonText}
              </button>
            </a>
          )}
        </li>
      </ul>
    </div>
  );
}
