import React from "react";
import { Row, Col } from "reactstrap";
import ocv from "../../../assets/OCV_Official.png";
// import sheriff from "../../../assets/Sheriff_White.png";
// import sheriffDark from "../../../assets/SheriffAppDark.png";
import sheriffBlack from "../../../assets/SheriffBlack.png";
import ema from "../../../assets/Ema_logo_white.png";
import fire from "../../../assets/Fire_Long_V2.png";
import police from "../../../assets/Police_Long.png";
import publicHealth from "../../../assets/Public_Health_Long.png";
import { NavLink } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem/ListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import List from "@material-ui/core/List/List";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import "./Footer.css";

interface IVerticalMarket {
  [id: string]: {
    [id2: string]: any;
  };
}

export default function Footer(props: any) {
  // modify to include [footerConfig.vertical] and not just title
  const { classes, manifestData, footerConfig } = props;
  let verticalMarkets: IVerticalMarket = {
    sheriff: {
      href: "https://thesheriffapp.com", // dark option to be handled elsewhere
      alt: "The Sheriff App",
      img: sheriffBlack,
    },
    police: {
      href: "https://thepoliceapp.com",
      alt: "The Police App",
      img: police,
    },
    ema: {
      href: "https://myemaapp.com",
      alt: "My EMA App",
      img: ema,
    },
    fire: {
      href: "https://thefiredepartmentapp.com",
      alt: "The Fire Department App",
      img: fire,
    },
    health: {
      href: "https://thepublichealthapp.com",
      alt: "The Public Health App",
      img: publicHealth,
    },
    ocv: {
      href: "https://ocvapps.com",
      alt: "OCV Apps",
      img: ocv,
    },
  };

  const SimpleFooter = (classes: any, manifestData: any, footerConfig: any) => (
    <Row className={classes.footer}>
      <Col lg={1} md={3}>
        <img
          className={classes.footerIcon}
          src={
            manifestData["stylesheet"].images
              ? manifestData["stylesheet"].images[footerConfig.icon]["url"]
              : ""
          }
          alt={
            manifestData["stylesheet"].images
              ? manifestData["stylesheet"].images[footerConfig.icon]["altText"]
              : ""
          }
        />
      </Col>
      <Col
        className={classes.columnA}
        style={{ paddingLeft: 0, paddingRight: 0 }}
        lg={6}
        md={5}
      >
        <div className={classes.footerInfo}>
          <h2 className="footerInfo">{footerConfig.title}</h2>
          <h5
            className="footerInfo"
            dangerouslySetInnerHTML={{ __html: footerConfig.address }}
          />
          <h5 className="footerInfo">Phone: {footerConfig.phone}</h5>
        </div>
      </Col>
      <Col
        className="columnB"
        style={{ paddingLeft: "20px", paddingRight: "20px" }}
        lg={5}
        md={4}
        sm={12}
      >
        <div className={classes.footerNav}>
          {footerConfig["includeHome"] ? (
            <NavLink to={"/"} className={classes.footerNavItem}>
              Home
            </NavLink>
          ) : (
            ""
          )}
          {footerConfig["menuItems"].map((item: string, index: number) =>
            index !== footerConfig["menuItems"].length - 1 ? (
              <NavLink
                key={item + index}
                className={classes.footerNavItem}
                to={"/" + item}
              >
                {manifestData["features"][item]["title"] + " / "}
              </NavLink>
            ) : (
              <NavLink
                key={item + index}
                className={classes.footerNavItem}
                to={"/" + item}
              >
                {manifestData["features"][item]["title"]}
              </NavLink>
            )
          )}
          <br />
          <h6 className="poweredBy">Powered by</h6>
          <a
            rel="noopener noreferrer"
            href={verticalMarkets[footerConfig.vertical].href}
            target={"_blank"}
          >
            <img
              className="footerImgOne"
              src={verticalMarkets[footerConfig.vertical].img}
              alt={footerConfig.vertical.alt}
            />
          </a>
        </div>
      </Col>
    </Row>
  );

  const ComplexFooter = (
    classes: any,
    manifestData: any,
    footerConfig: any
  ) => (
    <div className="mt-16 lg:mt-24">
      <Row
        className="complexFooterRowOne pt-0"
        style={{
          background:
            manifestData["stylesheet"]["colors"][
              footerConfig["phoneNumberCardBackground"]
            ],
        }}
      >
        <Col xs={12} key="col2">
          <div className="col2div">
            <Row>
              {footerConfig["menuItems"].map((item: string, index: number) => (
                <>
                  <Col
                    className={"footerColOne d-lg-none"}
                    xs={6}
                    sm={4}
                    md={4}
                  >
                    <NavLink
                      key={item + index}
                      className={classes.footerNavItem}
                      to={"/" + item}
                    >
                      {manifestData["features"][item]["title"]}
                    </NavLink>
                  </Col>
                  {footerConfig["includeHome"] && index === 0 ? (
                    <NavLink
                      to={"/"}
                      className={classes.footerNavItem + " d-none d-lg-block"}
                    >
                      Home
                    </NavLink>
                  ) : (
                    ""
                  )}
                  <NavLink
                    className={classes.footerNavItem + " d-none d-lg-block"}
                    key={item + index}
                    to={"/" + item}
                  >
                    {manifestData["features"][item]["title"]}
                  </NavLink>
                </>
              ))}
            </Row>
          </div>
        </Col>
      </Row>
      <Row className={"complexFooterRowTwo bg-white" + classes.footer}>
        <img
          className="footerImgTwo"
          src={
            manifestData["stylesheet"].images
              ? manifestData["stylesheet"].images[footerConfig.icon]["url"]
              : ""
          }
          alt={
            manifestData["stylesheet"].images
              ? manifestData["stylesheet"].images[footerConfig.icon]["altText"]
              : ""
          }
        />
        <Col className="footerColTwo" xs={10}>
          <h4
            style={{
              color: "#000",
              letterSpacing: "1px",
              fontSize: "1.3em",
            }}
            className="uppercase"
          >
            {footerConfig.title}
          </h4>
          <span dangerouslySetInnerHTML={{ __html: footerConfig.address }} />
          <a
            className="footerAnchorTwo"
            style={{
              color: "#000",
            }}
            href={"tel:" + footerConfig.phone}
          >
            {footerConfig.phone}
          </a>
          <div className="text-red-500 font-bold">
            <p>
              In An Emergency That Requires Police, Fire, Or Medical Assistance,
            </p>
            <p>Call 911 Immediately.</p>
          </div>
          <p className="text-black py-3">
            To Report A Crime Or Incident: Call{" "}
            <a href="tel:618-244-8004" className="hover:text-black">
              618-244-8004 (TDD Accessible)
            </a>
          </p>
          <div>
            <h6 className="footerH6">
              Copyright &copy;{new Date().getFullYear()}. Powered by{" "}
              <a
                rel="noopener noreferrer"
                href={
                  verticalMarkets[footerConfig.vertical]
                    ? verticalMarkets[footerConfig.vertical].href
                    : verticalMarkets["ocv"].href
                }
                target={"_blank"}
              >
                <img
                  className="footerImageTwo relative mx-auto max-h-12 inline"
                  src={
                    verticalMarkets[footerConfig.vertical]
                      ? verticalMarkets[footerConfig.vertical].img
                      : verticalMarkets["ocv"].img
                  }
                  alt={footerConfig.vertical.alt}
                />
              </a>
            </h6>
          </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <div id={props.anchorID}>
      {footerConfig["subtype"] === "simple" &&
        SimpleFooter(classes, manifestData, footerConfig)}
      {footerConfig["subtype"] === "complex" &&
        ComplexFooter(classes, manifestData, footerConfig)}
    </div>
  );
}
