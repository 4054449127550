import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import List from "@material-ui/core/List/List";
import { NavLink, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@material-ui/core/IconButton/IconButton";
import ReactModal from "react-modal";
import OCVVideoPlayer from "../../OCVFeatures/OCVVideoPlayer/OCVVideoPlayer";
import FacebookWidget from "../FacebookWidget";
import TwitterWidget from "../TwitterWidget";
import OCVBlogSlider from "../../OCVFeatures/OCVBlog/OCVBlogSlider/OCVBlogSlider";
import HorizontalSublayout from "../HorizontalSublayout/HorizontalSublayout";

ReactModal.setAppElement("#root");

export default function NavSubmenu(props: any) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalView, setModalView] = useState(
    Object.keys(props.manifestData["views"])[0]
  );
  const [modalTitle, setModalTitle] = useState("");

  const handleOpenModal = (view: string, title: string) => {
    setShowModal(true);
    setModalView(view);
    setModalTitle(title);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const getView = (view: string, manifestData: any, classes: any) => {
    switch (manifestData["views"][view]["type"]) {
      case "imageView":
        return (
          <img
            className={"NavSubmenuImg img-fluid"}
            src={
              manifestData["stylesheet"].images
                ? manifestData["stylesheet"].images[
                    manifestData["views"][view]["config"]["image"]
                  ]["url"]
                : ""
            }
            alt={
              manifestData["stylesheet"].images
                ? manifestData["stylesheet"].images[
                    manifestData["views"][view]["config"]["image"]
                  ]["altText"]
                : ""
            }
          />
        );
      case "button":
        return (
          <div
            style={{
              paddingTop: manifestData["views"][view]["config"]["paddingTop"]
                ? manifestData["views"][view]["config"]["paddingTop"]
                : 0,
              paddingBottom: manifestData["views"][view]["config"][
                "paddingBottom"
              ]
                ? manifestData["views"][view]["config"]["paddingBottom"]
                : 0,
              paddingLeft: manifestData["views"][view]["config"]["paddingLeft"]
                ? manifestData["views"][view]["config"]["paddingLeft"]
                : 0,
              paddingRight: manifestData["views"][view]["config"][
                "paddingRight"
              ]
                ? manifestData["views"][view]["config"]["paddingRight"]
                : 0,
            }}
          >
            {manifestData["features"][
              manifestData["views"][view]["config"]["feature"]
            ]["type"] === "modal" ? (
              <button
                style={{
                  background: manifestData["views"][view]["config"][
                    "backgroundColor"
                  ]
                    ? manifestData["views"][view]["config"]["backgroundColor"]
                    : manifestData["stylesheet"]["colors"]["primary"],
                }}
                type={"button"}
                onClick={() =>
                  handleOpenModal(
                    manifestData["features"][
                      manifestData["views"][view]["config"]["feature"]
                    ]["view"],
                    manifestData["features"][
                      manifestData["views"][view]["config"]["feature"]
                    ]["title"]
                  )
                }
                className={classes.homepageButton}
              >
                {manifestData["views"][view]["config"]["title"]}
              </button>
            ) : manifestData["features"][
                manifestData["views"][view]["config"]["feature"]
              ]["type"] !== "webview" ||
              !manifestData["features"][
                manifestData["views"][view]["config"]["feature"]
              ]["subtype"].includes("external") ? (
              <Link to={manifestData["views"][view]["config"]["feature"]}>
                <button
                  style={{
                    background: manifestData["views"][view]["config"][
                      "backgroundColor"
                    ]
                      ? manifestData["views"][view]["config"]["backgroundColor"]
                      : manifestData["stylesheet"]["colors"]["primary"],
                  }}
                  type={"button"}
                  className={classes.homepageButton}
                >
                  {manifestData["views"][view]["config"]["title"]}
                </button>
              </Link>
            ) : (
              <a
                target={"_blank"}
                rel="noopener noreferrer"
                href={
                  manifestData["features"][
                    manifestData["views"][view]["config"]["feature"]
                  ]["url"]
                }
              >
                <button
                  style={{
                    background: manifestData["views"][view]["config"][
                      "backgroundColor"
                    ]
                      ? manifestData["views"][view]["config"]["backgroundColor"]
                      : manifestData["stylesheet"]["colors"]["primary"],
                  }}
                  type={"button"}
                  className={classes.homepageButton}
                >
                  {manifestData["views"][view]["config"]["title"]}
                </button>
              </a>
            )}
          </div>
        );
      case "label":
        return (
          <p
            dangerouslySetInnerHTML={{
              __html: manifestData["views"][view]["config"]["text"],
            }}
          />
        );
      case "horizontalLayout":
        return (
          <HorizontalSublayout
            classes={classes}
            manifestData={manifestData}
            config={manifestData["views"][view]["config"]}
          />
        );
      case "video":
        return (
          <OCVVideoPlayer config={manifestData["views"][view]["config"]} />
        );
      case "facebookWidget":
        return (
          <FacebookWidget config={manifestData["views"][view]["config"]} />
        );
      case "twitterWidget":
        return <TwitterWidget config={manifestData["views"][view]["config"]} />;
      case "blogSlider":
        return (
          <OCVBlogSlider
            link={
              manifestData["features"][
                manifestData["views"][view]["config"]["feature"]
              ]["url"]
            }
          />
        );
    }
  };

  const { title, classes, items, manifestData, thirdLevel } = props;
  let navItems = items !== null && manifestData !== null && (
    <div className="absolute shadow-lg z-10 w-full lg:w-screen inset-x-0 lg:pointer-events-none">
      <div className="absolute inset-0 flex" aria-hidden="true">
        <div style={{ backgroundColor: "#8F7B5F" }} className="w-full"></div>
      </div>
      <div className="relative w-full grid grid-cols-1">
        <nav
          style={{ backgroundColor: "#8F7B5F" }}
          className={`grid mx-4 py-8 lg:grid-cols-5 lg:gap-x-12 lg:py-12 lg:px-6 xl:pr-12 h-96 lg:h-auto overflow-x-hidden overflow-y-auto lg:overflow-y-hidden`}
          aria-labelledby="solutionsHeading"
        >
          {items.map((item: { title: string; features: Array<string> }) => (
            <div>
              <h3
                style={{ fontSize: "1.2rem" }}
                className="text-md text-white text-center whitespace-pre-wrap font-medium tracking-wide uppercase"
              >
                {item.title}
              </h3>
              <hr className="bg-white mt-2" />
              <ul className="lg:mt-5 mb-4 lg:mb-0 lg:space-y-6">
                {item.features.map((feature: string, i: number) =>
                  manifestData["features"] &&
                  manifestData["features"][feature] &&
                  manifestData["features"][feature]["type"] ? (
                    manifestData["features"][feature]["type"] === "modal" ? (
                      <li className="flow-root pointer-events-auto lg:mr-4">
                        <span
                          key={i + feature}
                          className="-m-3 px-3 py-3 flex items-center rounded-md text-sm lg:text-base font-medium lg:hover:bg-blue-950 text-white lg:transition lg:ease-in-out duration-150"
                          onClick={() =>
                            handleOpenModal(
                              manifestData["features"][feature]["view"],
                              manifestData["features"][feature]["title"]
                            )
                          }
                        >
                          <span className="lg:ml-4 whitespace-pre-wrap">
                            {manifestData["features"][feature]["title"]}
                          </span>
                        </span>
                      </li>
                    ) : manifestData["features"][feature]["type"] ===
                        "webview" &&
                      !manifestData["features"][feature]["subtype"].includes(
                        "internal"
                      ) ? (
                      <li className="flow-root pointer-events-auto lg:mr-4">
                        <a
                          key={i + feature}
                          rel="noopener noreferrer"
                          target="_blank"
                          className="-m-3 px-3 py-3 flex items-center rounded-md text-sm lg:text-base font-medium lg:hover:bg-blue-950 text-white lg:transition lg:ease-in-out duration-150"
                          href={manifestData["features"][feature]["url"]}
                        >
                          <span className="lg:ml-4 whitespace-pre-wrap">
                            {manifestData["features"][feature]["title"]}
                          </span>
                        </a>
                      </li>
                    ) : (
                      <li className="flow-root pointer-events-auto lg:mr-4">
                        <NavLink
                          key={i + feature}
                          to={"/" + feature}
                          className="-m-3 px-3 py-3 flex items-center rounded-md text-sm lg:text-base font-medium lg:hover:bg-blue-950 text-white lg:transition lg:ease-in-out duration-150"
                        >
                          <span className="lg:ml-4 whitespace-pre-wrap">
                            {manifestData["features"][feature]["title"]}
                          </span>
                        </NavLink>
                      </li>
                    )
                  ) : (
                    ""
                  )
                )}
              </ul>
            </div>
          ))}
        </nav>
      </div>
    </div>
  );

  return (
    <>
      <Dropdown
        className={classes.navSubmenuTitle}
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle
          tag="a"
          onClick={toggle}
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
        >
          {title}
        </DropdownToggle>
        <DropdownMenu
          className={
            "w-full lg:w-screen -mr-2 py-0 mt-3 " +
            (!thirdLevel ? classes.navSubmenu : classes.navThirdLevelSubmenu)
          }
        >
          <List style={{ padding: "0" }}>{navItems}</List>
        </DropdownMenu>
      </Dropdown>
      <ReactModal
        className="NavSubmenuModalContent"
        overlayClassName="NavSubmenuModal"
        contentLabel="Minimal Modal Example"
        onRequestClose={() => handleCloseModal()}
        isOpen={showModal}
      >
        <div className="NavSubmenuDiv">
          <h4 className="NavSubmenuModalTitle">{modalTitle}</h4>
          <IconButton
            aria-label="Close"
            className="NavSubmenuCloseButton"
            onClick={() => handleCloseModal()}
          >
            <FontAwesomeIcon
              className="NavSubmenuIcon"
              icon={["fas", "times"]}
            />
          </IconButton>
        </div>
        {getView(modalView, manifestData, classes)}
      </ReactModal>
    </>
  );
}