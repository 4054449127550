import React from "react";

export default function SummaryImage(props: any) {
  const [blogData, setBlogData] = React.useState<any>([]);
  React.useEffect(() => {
    fetch(props.manifestData["features"][props.viewData["feature"]]["url"], {
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        setBlogData(data);
      });
  }, [props.manifestData, props.viewData]);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const handleDates = (date: any) => {
    const d = new Date(date * 1000);
    const newDate =
      months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
    return newDate;
  };
  return (
    <div
      id={props.anchorID}
      className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8 bg-cover"
      style={{
        backgroundColor: "#eee",
        backgroundImage:
          props.viewData.config.rgb !== undefined &&
          props.viewData.config.backgroundImageOpacity !== undefined
            ? `linear-gradient(rgba(${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.backgroundImageOpacity}), 
        rgba(${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.backgroundImageOpacity})), url(${props.viewData.config.backgroundImageURL})`
            : `url(${props.viewData.config.backgroundImageURL})`,
      }}
    >
      <div className="relative max-w-7xl mx-auto h-auto">
        <div className="text-center flex flex-col items-center justify-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            {props.viewData.title}
          </h2>
          <div
            style={{ borderColor: props.viewData.config.divideColor }}
            className="mt-3 border-b-4 w-1/6"
          ></div>
        </div>
        <div className="mt-12 mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none h-auto">
          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden max-h-96">
            <div className="flex-shrink-0">
              <img
                className="h-48 w-full object-cover"
                src={
                  blogData[0]?.images[0]?.large
                    ? blogData[0]?.images[0]?.large
                    : props.manifestData.stylesheet.images[
                        props.viewData.badgeImage
                      ]?.url
                }
                alt=""
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                {blogData.length > 0 && (
                  <>
                    <h1 className="font-semibold mb-1">
                      {handleDates(blogData[0]?.date.sec)}
                    </h1>
                    <div
                      style={{
                        borderColor: props.viewData.config.blogDivideColor,
                      }}
                      className="border-t-2 w-1/6 mb-2"
                    ></div>
                  </>
                )}
                <a
                  href={`${props.viewData["feature"]}/${blogData[0]?._id["$id"]}`}
                  className="block mt-2"
                >
                  <p className="text-xl font-semibold text-gray-900">
                    {blogData[0]?.title}
                  </p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        blogData[0]?.content.length > 200
                          ? blogData[0]?.content + "<span>...</span>"
                          : blogData[0]?.content,
                    }}
                    className="mt-2 text-base text-gray-500"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden max-h-96">
            <div className="flex-shrink-0">
              <img
                className="h-48 w-full object-cover"
                src={
                  blogData[1]?.images[0]?.large
                    ? blogData[1]?.images[0]?.large
                    : props.manifestData.stylesheet.images[
                        props.viewData.badgeImage
                      ]?.url
                }
                alt=""
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                {blogData.length > 0 && (
                  <>
                    <h1 className="font-semibold mb-1">
                      {handleDates(blogData[1]?.date.sec)}
                    </h1>
                    <div
                      style={{
                        borderColor: props.viewData.config.blogDivideColor,
                      }}
                      className="border-t-2 w-1/6 mb-2"
                    ></div>
                  </>
                )}
                <a
                  href={`${props.viewData["feature"]}/${blogData[1]?._id["$id"]}`}
                  className="block mt-2"
                >
                  <p className="text-xl font-semibold text-gray-900">
                    {blogData[1]?.title}
                  </p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        blogData[1]?.content.length > 200
                          ? blogData[1]?.content + "<span>...</span>"
                          : blogData[1]?.content,
                    }}
                    className="mt-2 text-base text-gray-500"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden max-h-96">
            <div className="flex-shrink-0">
              <img
                className="h-48 w-full object-cover"
                src={
                  blogData[2]?.images[0]?.large
                    ? blogData[2]?.images[0]?.large
                    : props.manifestData.stylesheet.images[
                        props.viewData.badgeImage
                      ]?.url
                }
                alt=""
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                {blogData.length > 0 && (
                  <>
                    <h1 className="font-semibold mb-1">
                      {handleDates(blogData[2]?.date.sec)}
                    </h1>
                    <div
                      style={{
                        borderColor: props.viewData.config.blogDivideColor,
                      }}
                      className="border-t-2 w-1/6 mb-2"
                    ></div>
                  </>
                )}
                <a
                  href={`${props.viewData["feature"]}/${blogData[2]?._id["$id"]}`}
                  className="block mt-2"
                >
                  <p className="text-xl font-semibold text-gray-900">
                    {blogData[2]?.title}
                  </p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        blogData[2]?.content.length > 200
                          ? blogData[2]?.content + "<span>...</span>"
                          : blogData[2]?.content,
                    }}
                    className="mt-2 text-base text-gray-500"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-12">
          <a href={props.viewData["feature"]} className="block">
            <button
              type="button"
              style={{
                color: props.viewData["config"]["buttonTextColor"]
                  ? props.viewData["config"]["buttonTextColor"]
                  : "white",
                background: props.viewData["config"]["buttonColor"]
                  ? props.viewData["config"]["buttonColor"]
                  : "blue",
              }}
              className="inline-flex items-center px-12 py-3 border border-transparent text-lg font-semibold rounded-xl shadow-sm focus:outline-none"
            >
              View All
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
