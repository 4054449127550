import React, { useEffect, useState } from "react";
import BlogFAQItem from "./OCVBlogFAQItem";
import axios from "axios";

const BlogFAQ = (props: any) => {
  const { viewData, link, anchorID } = props;
  const [blogData, setBlogData] = useState([]);
  useEffect(() => {
    fetch(link, { mode: "cors" })
      .then((res) => res.json())
      .then((res) => setBlogData(res))
      .catch((err) => console.error(err));
  }, [link]);
  return (
    <div id={anchorID} className="wrapper">
      <div
        className="bg-cover"
        style={{
          backgroundImage:
            props.viewData.config.rgb !== undefined &&
            props.viewData.config.backgroundImageOpacity !== undefined
              ? `linear-gradient(rgba(${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.backgroundImageOpacity}), 
          rgba(${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.backgroundImageOpacity})), url(${props.viewData.config.backgroundImageURL})`
              : `url(${props.viewData.config.backgroundImageURL})`,
        }}
      >
        <div className="max-w-7xl mx-auto py-10 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto ">
            <div className="flex flex-col items-center">
              <h2 className=" text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
                {viewData?.title}
              </h2>
              <div
                style={{
                  borderColor: viewData.config.hrColor
                    ? viewData.config.hrColor
                    : "black",
                }}
                className="mt-3 mb-2 border-b-4 w-1/6"
              />
            </div>
            {blogData.map((post: any, i: number) => {
              return (
                i < viewData.config.limit && (
                  <BlogFAQItem key={i} title={post.title}>
                    <p dangerouslySetInnerHTML={{ __html: post.content }}></p>
                    <div className="flex flex-row items-center justify-center  ">
                      {post.images.map((image: any, i: number) => (
                        <img
                          style={{ height: "300px", width: "250px" }}
                          src={image.small}
                          className="p-2"
                        />
                      ))}
                    </div>
                  </BlogFAQItem>
                )
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogFAQ;
