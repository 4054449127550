import React from "react";
import { Row, Col } from "reactstrap";
import OCVContactsEntry from "../OCVContactsEntry/OCVContactsEntry";
import Paper from "@material-ui/core/Paper/Paper";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import ReactLoading from "react-loading";
import Pagination from "../../../MainComponents/Pagination";
import "./OCVContacts.css";

export default class OCVContacts extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      contactsData: null,
      contactHeaders: [],
      appID: "",
      headerValue: "all",
      currentPage: 1,
      totalPages: 0,
      currentEntries: [],
      search: "",
    };
  }

  handleHeaderChange = (event: any, headerValue: any) => {
    this.setState({
      headerValue: headerValue,
      currentData: this.state.contactsData[headerValue],
    });
  };

  onPageChanged = (data: {
    currentPage: number;
    totalPages: number;
    pageLimit: number;
  }) => {
    const { currentPage, totalPages, pageLimit } = data;
    const { currentData } = this.state;
    const offset = (currentPage - 1) * pageLimit;
    const currentEntries = currentData.slice(offset, offset + pageLimit);
    this.setState({ currentPage, currentEntries, totalPages });
  };

  handleSearch = (event: any) => {
    this.setState({ search: event.target.value });
  };

  componentDidMount() {
    const { link } = this.props;

    try {
      fetch(link, { mode: "cors" })
        .then((response) => response.json())
        .then((data) => {
          let headers = data["headers"];
          let entries = data["entries"];
          let completeList: any[] = [];
          for (let i = 0; i < data["headers"].length; i++) {
            completeList = completeList.concat(
              data["entries"][data["headers"][i]]
            );
          }
          if (this.props.subtypes.includes("showAllHeader")) {
            headers[0] = "all";
          } else if (
            headers[headers.length - 1].toString().includes("default") &&
            (entries["default"] == null || entries["default"] === undefined)
          ) {
            headers.pop();
          }
          this.setState({
            headerValue: headers[0],
            contactsData: data["entries"],
            contactHeaders: headers,
            contactHeadersMinusDefault: headers,
            appID: data["appID"],
            currentData: data["entries"][headers[0]],
            completeList,
          });
        });
    } catch (e) {}
  }

  render() {
    const { classes, showSearch } = this.props;
    const { search } = this.state;

    if (this.state.contactsData === null) {
      return (
        <div className="OCVContactsDiv">
          <ReactLoading
            className={"loading-centered"}
            type={"bars"}
            color={"#000"}
            height={"10%"}
            width={"10%"}
          />
        </div>
      );
    } else {
      return (
        <div
          id={this.props.anchorID}
          className="bg-cover"
          style={{
            backgroundImage:
              this.props?.viewData?.config?.rgb !== undefined &&
              this.props?.viewData?.config?.backgroundImageOpacity !== undefined
                ? `linear-gradient(rgba(${this.props.viewData.rgb}, ${this.props.viewData.rgb}, ${this.props.viewData.rgb}, ${this.props.viewData.backgroundImageOpacity}), 
          rgba(${this.props.viewData.rgb}, ${this.props.viewData.rgb}, ${this.props.viewData.rgb}, ${this.props.viewData.backgroundImageOpacity})), url(${this.props.viewData.backgroundImageURL})`
                : `url(${
                    this.props?.viewData?.config
                      ? this.props?.viewData?.config?.backgroundImageURL
                      : ""
                  })`,
          }}
        >
          <Row style={{ padding: "50px 0px" }}>
            {showSearch ? (
              <div className="p-2 w-10/12 sm:w-8/12 mx-auto h-10 mb-8">
                <input
                  type="search"
                  value={search}
                  onChange={this.handleSearch}
                  className="w-full rounded-full p-2"
                  placeholder="Search contact.."
                />
              </div>
            ) : null}
            <Col xs={12} lg={10} className="mt-8">
              <Paper className={classes.contactHeaderRoot}>
                <Tabs
                  value={this.state.headerValue}
                  onChange={this.handleHeaderChange}
                  scrollable
                  indicatorColor={"primary"}
                  scrollButtons="auto"
                >
                  {this.state.contactHeadersMinusDefault.map((item: any) => (
                    <Tab
                      key={item}
                      value={item}
                      href={"#" + item}
                      id={item}
                      label={item}
                    />
                  ))}
                </Tabs>
              </Paper>
              <div className={classes.contactsContainer}>
                <Row>
                  {this.state.currentData
                    .filter((item: any) =>
                      item?.title?.toLowerCase().includes(search)
                    )
                    .map((item: any, index: any) => (
                      <Col
                        key={item.title + index}
                        xs={12}
                        md={10}
                        lg={10}
                        className="p-0"
                      >
                        <OCVContactsEntry classes={classes} entry={item} />
                      </Col>
                    ))}
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={8}>
              <Pagination
                totalRecords={this.state.currentData.length}
                pageLimit={50}
                pageNeighbours={1}
                onPageChanged={this.onPageChanged}
              />
            </Col>
          </Row>
        </div>
      );
    }
  }
}
