import React from "react";
import { Link } from "react-router-dom";

export default function OCVBlogGridWidget(props: any) {
  const { viewData } = props;

  const [blogData, setBlogData] = React.useState<any>([]);

  React.useEffect(() => {
    fetch(viewData.url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => setBlogData(data));
  }, [viewData]);

  let cols = viewData.config.numCols;

  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  width < 640 && (cols = 3);

  let indexes = Array(cols).fill(null);
  return (
    <div
      id={props.anchorID}
      className="pb-4 bg-cover"
      style={{
        backgroundColor: viewData.config.bgColor,
        backgroundImage:
          viewData.config.rgb !== undefined &&
          viewData.config.backgroundImageOpacity !== undefined
            ? `linear-gradient(rgba(${viewData.config.rgb}, ${viewData.config.rgb}, ${viewData.config.rgb}, ${viewData.config.backgroundImageOpacity}), 
        rgba(${viewData.config.rgb}, ${viewData.config.rgb}, ${viewData.config.rgb}, ${viewData.config.backgroundImageOpacity})), url(${viewData.config.backgroundImageURL})`
            : `url(${viewData.config.backgroundImageURL})`,
      }}
    >
      <div
        className={` grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-${cols} pt-4`}
      >
        {indexes.map((item, index: number) => (
          <div className="col-span-1 mx-2 my-3">
            <div className="pb-4 ">
              {cols === 3 && (
                <img
                  alt=""
                  src={blogData[index]?.images[0]?.large}
                  className="w-auto h-11/12 object-cover mx-auto"
                  style={{ height: "400px" }}
                />
              )}
              {cols === 4 && (
                <img
                  alt=""
                  src={blogData[index]?.images[0]?.large}
                  className="w-auto h-11/12 object-cover mx-auto"
                  style={{ height: "400px" }}
                />
              )}
              {cols === 5 && (
                <img
                  alt=""
                  src={blogData[index]?.images[0]?.large}
                  className="w-auto h-11/12 object-cover mx-auto"
                  style={{ height: "400px" }}
                />
              )}
              {cols === 6 && (
                <img
                  alt=""
                  src={blogData[index]?.images[0]?.large}
                  className="w-auto h-11/12 object-cover mx-auto"
                  style={{ height: "400px" }}
                />
              )}
            </div>
            <div
              className={`w-full mx-auto text-${viewData.config.titleColor} text-lg font-semibold lg:text-xl text-center leading-3 pb-2`}
            >
              {`${blogData[index]?.title}`}
            </div>
            <div className="flex flex-row">
              <button
                className={`w-1/2 mx-auto border-2 font-medium border-${viewData.config.buttonBorderColor} text-${viewData.config.buttonTextColor} `}
              >
                {viewData.config.buttonText}
              </button>
            </div>
          </div>
        ))}
      </div>
      <Link to={`/${viewData.config.feature}`}>
        <button className="block w-1/2 md:w-1/6 mx-auto border-2 border-white text-white mt-1">
          View All Posts
        </button>
      </Link>
    </div>
  );
}
