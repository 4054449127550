import React from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import "./Breadcrumb.css";

export default function Breadcrumb(props: any) {
  const { classes, title } = props;
  return (
    <Row className="breadcrumbRow">
      <Col xs={10} sm={10} md={10}>
        <h1 className={classes.breadcrumbHeader}>{title}</h1>
      </Col>
    </Row>
  );
}
