import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function SocialBar(props: any) {
  return (
    <div
      id={props.anchorID}
      className="bg-opacity-25 bg-cover"
      style={{
        background: props.viewData.backgroundColor,
        backgroundImage:
          props.viewData.config.rgb !== undefined &&
          props.viewData.config.backgroundImageOpacity !== undefined
            ? `linear-gradient(rgba(${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.backgroundImageOpacity}), 
        rgba(${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.backgroundImageOpacity})), url(${props.viewData.config.backgroundImageURL})`
            : `url(${props.viewData.config.backgroundImageURL})`,
      }}
    >
      <div className="max-w-7xl mx-auto py-8 px-4 sm:py-6 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-center">
          <div className="flex-1 flex justify-center lg:justify-end ">
            <h2
              style={{ color: props.viewData.titleColor }}
              className="max-w-md text-3xl font-extrabold lg:max-w-xl uppercase"
            >
              {props.viewData.title}
            </h2>
          </div>
          <div className="flow-root flex-1 lg:mt-0 ">
            <div className=" md:-ml-6 flex flex-col md:flex-row space-y-4 md:space-y-0 md:flex-wrap justify-center md:justify-start lg:ml-4">
              {props.viewData.socials.map((item: any) => (
                <div
                  key={item.url}
                  className=" flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-12"
                >
                  <a
                    href={item.url}
                    rel="noreferrer noopener"
                    target="_blank"
                    className="block font-bold text-2xl space-x-2"
                  >
                    <FontAwesomeIcon
                      icon={["fab", item.title.toLowerCase()]}
                      color={props.viewData.iconColor}
                    />
                    {props.viewData.socialText && (
                      <span style={{ color: props.viewData.iconColor }}>
                        {item.title}
                      </span>
                    )}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
