import React from "react";
import { Col, Row } from "reactstrap";
import Paper from "@material-ui/core/Paper/Paper";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { withRouter } from "react-router-dom";
import "./OCVFAQ.css";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

export default withRouter(function OCVFAQ(props: any) {
  const preExpanded = props.selected;
  return (
    <>
      <Row id={props.anchorID}>
        <Col className="OCVFAQCol" xs={10}>
          <Paper className="OCVFAQPaper">
            <Accordion
              preExpanded={preExpanded !== null ? preExpanded : []}
              allowZeroExpanded={true}
            >
              <AccordionItem uuid="0">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    How long does it take for a person to be released?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    There is no exact time. Many factors must be considered.
                    <br />
                    <br />
                    Persons must be fingerprinted, photos taken, warrant checks
                    done, processed through N.C.I.C., checked by medical etc.
                    <br />
                    <br />
                    The AVERAGE processing time is less than two hours. However
                    actual release time may be 3 to 6 hours after the release is
                    ordered.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem uuid="1">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    How do I put money on an imate's account?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    A private company handles this. There are Kiosks in the
                    visitation lobby that accepts cash only. You can also click
                    this link,{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://web.connectnetwork.com/welcome-gtl/?utm_source=gtl.net%20Header%20Deposit%20Button&utm_medium=Web&utm_campaign=Deposit%20Funds%20Now%20to%20Choice%20Page"
                    >
                      Offender Connect
                    </a>{" "}
                    and create an account to send funds electronically.
                    <br />
                    <br />
                    INMATE ACCOUNT offices are located in the visitation lobby
                    at 201 Poplar and for female inmates at the Jail East
                    facility at 6201 Haley Rd.
                    <br />
                    <br />
                    Money may be put in an inmate's commissary account between
                    the hours of 8:00 a.m. and 7:00 p.m. seven days a week.
                    <br />
                    <br />A maximum of $200.00 may be left in a one week period
                    CURRENCY ONLY. We do not accept checks.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem uuid="2">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    How do I provide clothes for an inmate to wear to court?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Clothes for court appearance may be brought to the main
                    lobby of the Jail (201 Poplar) or Jail East. We accept
                    clothes 24 hours a day seven days a week.
                    <br />
                    <br />
                    Court clothes must be conservative.
                    <br />
                    Tennis shoes will NOT be accepted.
                    <br />
                    <br />
                    Inappropriate clothing for court appearances will NOT be
                    accepted (shorts, pants with multiple zippers etc).
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem uuid="3">
                <AccordionItemHeading>
                  <AccordionItemButton>How do I post bond?</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    The 24 hour court clerk’s office is also located in the Jail
                    Annex visitation area.
                    <br />
                    <br />
                    You may post a cash bond or use the services of a licensed
                    bonding company of your choice.
                    <br />
                    <br />
                    The Shelby County Sheriff’s Office does NOT make
                    recommendations as to which bonding company you use.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem uuid="4">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    How do I get an inmate's property?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Any inmate may release his property to anyone. The inmate
                    must initiate the request.
                    <br />
                    <br />
                    The inmate may request a “property release form” from the
                    pod Officer. The completed form is then sent to Aramark’s
                    office in the Annex visitation area. When proper I.D. is
                    presented, the property may be released.
                    <br />
                    <br />
                    Property may NOT be released if the property is part of
                    evidence or if Law Enforcement Officers have placed a “hold”
                    on the property.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem uuid="5">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    How do I send a letter to someone in jail?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Address the letter to:
                    <br />
                    <br />
                    <i>
                      The inmate’s full name
                      <br />
                      Booking number
                      <br />
                      Floor and pod where housed if known
                    </i>
                    <br />
                    201 Poplar Ave.
                    <br />
                    Memphis, TN 38103
                    <br />
                    <br />
                    OR Jail East Women's Facility
                    <br />
                    <br />
                    <i>
                      The inmate’s full name
                      <br />
                      Booking number
                      <br />
                      Location where housed if known
                    </i>
                    <br />
                    6201 Haley Rd.
                    <br />
                    Memphis, TN 38134
                    <br />
                    <br />
                    All letters sent in to inmates must have a complete return
                    address on them.
                    <br />
                    Books (soft back) or magazines must come directly from the
                    publisher(s).
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </Paper>
        </Col>
      </Row>
    </>
  );
});
