import React from "react";
import Pagination from "./Pagination/Pagination";
import PaginationArrow from "./Pagination/PaginationArrow";
import { Link } from "react-router-dom";

export default function BlogCarousel(props: any) {
  const [selected, setSelected] = React.useState<any>(0);
  const [cycle, setCycle] = React.useState(false);
  const [blogData, setBlogData] = React.useState<any>([]);
  const [testLimit, setTestLimit] = React.useState(5);
  let testTimer: any;

  React.useEffect(() => {
    fetch(
      props.manifestData["features"][props.viewData.config["feature"]]["url"],
      {
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setBlogData(data);
        setCycle(props.viewData.config.cycle);
        setTestLimit(props.viewData.config.testimonialLimit);
        setSelected(data[0] ? data[0]["_id"]["$id"] : "");
      });
  }, [props.manifestData, props.viewData]);

  React.useEffect(() => {
    if (cycle) {
      if (selected === blogData[testLimit]["_id"]["$id"]) {
        setSelected(blogData[0]["_id"]["$id"]);
      } else {
        testTimer = setTimeout(() => {
          blogData.map((info: any, i: any) => {
            if (selected === info["_id"]["$id"]) {
              setSelected(blogData[i + 1]["_id"]["$id"]);
            }
          });
        }, props.viewData.config.timer);
      }
    }
  }, [selected, cycle]);

  function stopTimer() {
    clearTimeout(testTimer);
  }
  return selected !== 0 ? (
    <div
      id={props.anchorID}
      style={{ background: props.viewData.config.bgColor }}
      className="w-full h-full flex flex-col justify-center"
    >
      <div className="text-center pt-4 font-bold w-full">
        <h1 className="text-white w-full text-4xl">
          {props.viewData.title.toUpperCase()}
        </h1>
        <hr
          className="mx-auto w-32 mt-2 py-0.5"
          style={{ background: props.viewData.config.hrColor }}
        />
      </div>
      <div className="w-full grid lg:grid-cols-2 lg:gap-3 lg:gap-y-0 gap-y-4 grid-cols-1 justify-items-center lg:max-h-56 mb-8 mt-4 lg:overflow-y-hidden">
        {selected ? (
          props.viewData.config.image.position === "right" ? (
            <>
              <Link
                to={
                  "/" +
                  props.viewData.config.feature +
                  "/" +
                  blogData.find((item: any) => item["_id"]["$id"] === selected)[
                    "_id"
                  ]["$id"]
                }
                className="w-1/2 flex max-h-56 overflow-y-hidden flex-col items-center lg:items-start lg:justify-self-end lg:ml-12 ml-0 mt-6"
              >
                <div className="mb-6 w-full">
                  <h1 className="text-xl text-white lg:text-left text-center font-bold leading-5 ">
                    {
                      blogData.find(
                        (item: any) => item["_id"]["$id"] === selected
                      ).title
                    }
                  </h1>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: blogData.find(
                      (item: any) => item["_id"]["$id"] === selected
                    )?.content,
                  }}
                  className=" text-xs md:text-sm text-white text-center"
                ></p>
              </Link>
              <div className="mt-6 w-1/2 h-1/2 md:w-1/3 md:h-1/3 lg:ml-20 xl:h-1/3 xl:w-1/3 lg:h-1/2 lg:w-1/2 lg:justify-self-start lg:mr-32">
                <img
                  style={{
                    height: props.viewData.config.image.height,
                    width: props.viewData.config.image.width,
                  }}
                  className="text-white border-2 border-white rounded-md object-left mx-auto"
                  src={
                    blogData.find(
                      (item: any) => item["_id"]["$id"] === selected
                    )?.images[0]?.large
                  }
                  alt="alt"
                ></img>
              </div>
            </>
          ) : (
            <>
              <div className="mt-6 w-1/2 h-1/2 md:w-1/3 md:h-1/3 lg:ml-20 xl:h-1/3 xl:w-1/3 lg:h-1/2 lg:w-1/2 lg:justify-self-end lg:mr-32">
                <img
                  style={{
                    height: props.viewData.config.image.height,
                    width: props.viewData.config.image.width,
                  }}
                  className="text-white border-2 border-white rounded-md mx-auto"
                  src={
                    blogData.find(
                      (item: any) => item["_id"]["$id"] === selected
                    )?.images[0]?.large
                  }
                  alt="alt"
                ></img>
              </div>
              <Link
                to={
                  "/" +
                  props.viewData.config.feature +
                  "/" +
                  blogData.find((item: any) => item["_id"]["$id"] === selected)[
                    "_id"
                  ]["$id"]
                }
                className="w-1/2 flex flex-col items-center lg:items-start lg:justify-self-start lg:ml-20 ml-0 mt-6 lg:my-auto"
              >
                <div className="mb-6 w-full">
                  <h1 className="text-xl text-white lg:text-left text-center font-bold leading-5">
                    {
                      blogData.find(
                        (item: any) => item["_id"]["$id"] === selected
                      ).title
                    }
                  </h1>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: blogData.find(
                      (item: any) => item["_id"]["$id"] === selected
                    )?.content,
                  }}
                  className=" text-xs md:text-sm text-white text-center"
                ></p>
              </Link>
            </>
          )
        ) : (
          <div className="text-center text-white font-semibold col-span-2">
            No content available - must have entries in your blog
          </div>
        )}
      </div>

      {selected ? (
        props.viewData.config.pagination.type === "bubble" ? (
          <>
            <div className="pt-6 flex flex-row mx-auto mb-2">
              {blogData.slice(0, testLimit).map((content: any, i: number) => (
                <Pagination
                  pageKey={content["_id"]["$id"]}
                  onClick={() => {
                    setSelected(content["_id"]["$id"]);
                    setCycle(false);
                    stopTimer();
                  }}
                  selectedBubble={selected}
                  bubbleState={selected === i ? "1" : "0"}
                  paginationColor={props.viewData.config.pagination}
                />
              ))}
            </div>
            <div className="mx-auto">
              <Link to={"/" + props.viewData.config.feature}>
                <button
                  style={{
                    background: props.viewData.config.bgColor,
                  }}
                  type="button"
                  className="mt-4 max-w-max inline-flex px-10 py-2 mb-4 border-2 text-base font-medium shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <div className="w-full text-center xl:text-lg tracking-tighter">
                    {props.viewData.config.button.text.toUpperCase()}
                  </div>
                </button>
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-row justify-center">
              <PaginationArrow
                onClickLeft={() => {
                  setCycle(false);
                  stopTimer();
                  const index = blogData.findIndex(
                    (blog: any) => blog["_id"]["$id"] === selected
                  );

                  if (selected === blogData[0]["_id"]["$id"]) {
                    setSelected(blogData[testLimit - 1]["_id"]["$id"]);
                  } else {
                    setSelected(blogData[index - 1]["_id"]["$id"]);
                  }
                }}
                onClickRight={() => {
                  setCycle(false);
                  stopTimer();
                  const index = blogData.findIndex(
                    (blog: any) => blog["_id"]["$id"] === selected
                  );

                  if (selected === blogData[testLimit - 1]["_id"]["$id"]) {
                    setSelected(blogData[0]["_id"]["$id"]);
                  } else {
                    setSelected(blogData[index + 1]["_id"]["$id"]);
                  }
                }}
                selected={selected}
                paginationColor={props.viewData.config.pagination}
              ></PaginationArrow>
            </div>
            {props.viewData.config.viewAll === true ? (
              <>
                <div className="mx-auto">
                  <Link to={"/" + props.viewData.config.feature}>
                    <button
                      style={{
                        background: props.viewData.config.bgColor,
                      }}
                      type="button"
                      className="mt-4 max-w-max inline-flex px-10 py-2 mb-4 border-2 mx-auto text-base font-medium shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <div className="w-full text-center xl:text-lg tracking-tighter">
                        {props.viewData.config.button.text}
                      </div>
                    </button>
                  </Link>
                </div>
              </>
            ) : (
              <h1>No content available - must have entries in your blog</h1>
            )}
            )
          </>
        )
      ) : (
        <></>
      )}
    </div>
  ) : (
    <div></div>
  );
}
