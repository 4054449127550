import React, { useState } from "react";

const BlogFAQItem = ({ title, children }: any) => {
  const [isOpen, setOpen] = useState(false);
  const showDescription = isOpen ? "block" : "hidden";
  const ariaExpanded = isOpen ? "true" : "false";

  return (
    <dl className="mt-4 divide-y divide-gray-200">
      <div className="">
        <dt className="text-lg border-2 border-black">
          <button
            type="button"
            className="uppercase text-left w-full flex justify-between items-start text-gray-400"
            aria-expanded={ariaExpanded}
            onClick={() => {
              setOpen((isOpen) => !isOpen);
            }}
          >
            <span className="font-extrabold ml-2 p-1 text-gray-900">
              {title}
            </span>
            <div className="bg-black p-1">
              <span
                className={`h-7 flex items-center transform transition ${
                  isOpen ? "rotate-180" : ""
                }`}
              >
                <svg
                  className="rotate-0 h-6 w-6 transform text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            </div>
          </button>
        </dt>
        <dd className="mt-4 mr-6 ml-6 w-full" id="faq-0">
          <div
            className={`text-base text-gray-500 w-full ${showDescription}`}
            id={`faq_desc`}
          >
            {children}
          </div>
        </dd>
      </div>
    </dl>
  );
};

export default BlogFAQItem;
